import { Email, Remove } from "@mui/icons-material";
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Button, Input } from "@uspto-desnsys/uspto-ds-react";
import { memo, useCallback, useState } from "react";
import { adminSaveApplication } from "../services/apiService";
import BusyOverlay from "./BusyOverlay";


const ApplicationEditForm = ({ application, onCloseEditor }) => {
    const [busy, setBusy] = useState(false);
    const [newApp] = useState(application?.name ? false : true);
    const [appData, setAppData] = useState({ ...application });
    const [selectedEmail, setSelectedEmail] = useState();
    const [emailValue, setEmailValue] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    const closeEditor = useCallback(() => {
        if (newApp && !window.confirm("Close without saving?"))
            return;

        onCloseEditor && onCloseEditor();
    }, [newApp, onCloseEditor]);

    const saveApplication = useCallback(async () => {
        try {
            setBusy(true);

            // Validate required fields
            const requiredFields = [
                "name",
                "path",
                "url"
            ]

            for (const field of requiredFields) {
                if (!appData[field]) {
                    alert("Name, Path and URL are required.");
                    return;
                }
            }

            let dataToSave = { ...appData };

            // verify path is formatted correctly
            let newPath = dataToSave.path.trim()
            if (!newPath.startsWith("/")) {
                newPath = "/" + newPath;
            }
            if (!newPath.endsWith("/")) {
                newPath += "/";
            }

            dataToSave.path = newPath

            // Save it
            const response = await adminSaveApplication(dataToSave);

            if (response.ok) {
                // Delete old app if Path or URL has changed
                if (!newApp && (dataToSave.path !== application.path || dataToSave.url !== application.url)) {
                    onCloseEditor && onCloseEditor(true);
                } else {
                    onCloseEditor && onCloseEditor(false);
                }
            } else {
                alert("Error occured while saving the application. " + response.statusText)
            }

        } finally {
            setBusy(false);
        }
    }, [appData, application, newApp, onCloseEditor]);

    const updateFieldValue = useCallback((fieldName, value) => {
        setAppData(prev => {
            return {
                ...prev,
                [fieldName]: value
            };
        })
    }, []);

    // Add an email to the user list
    const addUserEmail = useCallback(() => {
        if (!validEmail) return;

        let newList = new Set(appData.userEmails);
        newList.add(emailValue.trim());

        updateFieldValue("userEmails", [...newList]);

        setEmailValue("");
    }, [appData, updateFieldValue, emailValue, validEmail]);

    const deleteUserEmail = useCallback((email) => {
        let newList = [
            ...appData.userEmails
        ];

        newList.splice(newList.indexOf(email), 1);

        updateFieldValue("userEmails", newList);

        setEmailValue("");
    }, [appData, updateFieldValue]);

    return (
        <div>
            <BusyOverlay busy={busy} />
            <h1
                style={{
                    fontSize: 24
                }}
            >
                {newApp ? "Add a New Application to Labs" : `Editing ${appData.name}`}
            </h1>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    border: "1px solid darkgray",
                    maxWidth: 900
                }}
            >
                {/** Form */}
                <div
                    className=""
                    style={{
                        display: "inline-block"
                    }}
                >
                    <div style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            labelText="Application Name"
                            maxLength={100}
                            value={appData.name || ""}
                            onChange={(input) => updateFieldValue("name", input.target.value)}
                        />
                    </div>

                    <div style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            labelText="Path"
                            hintText="The unique URL path for each application (/appname/) - the URL will start with this if hosted inside Labs"
                            maxLength={50}
                            value={appData.path || ""}
                            onChange={(input) => updateFieldValue("path", input.target.value?.trim())}
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            labelText="URL"
                            hintText="Use relative URL (/appname/...)  if the app is hosted inside Labs, otherwise full URL (https://myapp.uspto.gov)"
                            maxLength={500}
                            value={appData.url || ""}
                            onChange={(input) => updateFieldValue("url", input.target.value?.trim())}
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            labelText="Lifecycle"
                            hintText="The application's lifecycle (POC, PROTOTYPE, DEMO, ALPHA, BETA)"
                            maxLength={20}
                            value={appData.lifecycle || ""}
                            onChange={(input) => updateFieldValue("lifecycle", input.target.value)}
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            labelText="Version Number"
                            hintText=""
                            maxLength={10}
                            value={appData.version || ""}
                            onChange={(input) => {
                                if (!isNaN(input.target.value))
                                    updateFieldValue("version", input.target.value)
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            labelText="Description"
                            hintText="A short description of the application (max 200 characters)"
                            maxLength={200}
                            value={appData.description || ""}
                            onChange={(input) => updateFieldValue("description", input.target.value)}
                        />
                    </div>
                    <h1 style={{ fontSize: 24 }}>User Access List</h1>
                    <div
                        style={{
                            marginBottom: 40,
                            display: "flex",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "gray",
                                padding: 2
                            }}
                        >
                            <List
                                id="user-email-list"
                                sx={{
                                    width: '100%',
                                    maxWidth: 700,
                                    minWidth: 400,
                                    bgcolor: '#f5f5f5',
                                    maxHeight: 300,
                                    overflowY: "scroll",

                                }}

                                component="nav"
                                aria-labelledby="user-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="user-list-subheader">
                                        Users who can launch the application from Labs
                                    </ListSubheader>
                                }
                            >
                                {
                                    appData.userEmails?.toSorted().map((email) => (
                                        <ListItemButton
                                            key={email}
                                            onClick={() => setSelectedEmail(email)}
                                            selected={selectedEmail === email}
                                        >
                                            <ListItemButton
                                                title={"Remove " + email}
                                                onClick={() => deleteUserEmail(email)}
                                                style={{
                                                    maxWidth: 35,
                                                    padding: 5
                                                }}
                                            >
                                                <Remove style={{ color: "darkred" }} />
                                            </ListItemButton>
                                            <ListItemIcon
                                                style={{
                                                    padding: 5,
                                                    minWidth: 20,
                                                    marginRight: 10
                                                }}
                                            >
                                                <Email />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {email}
                                            </ListItemText>
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: 20
                            }}
                        >
                            <label id="email-input-label" htmlFor="email-input">Add a User to the Access List</label>
                            <div className="form-hint" htmlFor="email-input">Allow all authenticated users with *</div>
                            <div
                                style={{
                                    marginBottom: 30,
                                    display: "flex"
                                }}
                            >
                                <Input
                                    id="email-input"
                                    type="email"
                                    aria-labelledby="email-input-label"
                                    maxLength={60}
                                    placeholder="someone@uspto.gov"
                                    value={emailValue}
                                    onChange={(e) => {
                                        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
                                        setValidEmail(emailRegex.test(e.target.value) || e.target.value === "*")

                                        setEmailValue(e.target.value.toLowerCase().trim());
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" && addUserEmail()}
                                />
                                <Button
                                    style={{
                                        whiteSpace: "nowrap",
                                        padding: 5,
                                        borderRadius: 0,
                                        marginLeft: 4
                                    }}
                                    disabled={!validEmail}
                                    onClick={addUserEmail}
                                >
                                    <Remove />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/** Action Buttons */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 20
                    }}
                >
                    <Button
                        onClick={saveApplication}
                    >
                        Save
                    </Button>
                    <Button
                        variant="light"
                        onClick={closeEditor}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default memo(ApplicationEditForm);